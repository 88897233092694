<template>
  <div>
    <h1>Demandes ouvertures</h1>
    <DemandesOuvertureEditor
        ref="DemandeForm"
        :key="newDemandeModalKey"
        :demande="selectedDemande"
        :users="users"
        :visible="visible"
        :processing="loading"
        :medecin-list="medecinList"
        :current-user="user"
        @cancel="closeModal"
        @close="closeModal"
        @create="handleCreate"
    />
    <a-row type="flex" justify="space-between" class="table_head">
      <a-col :xs="24" :sm="4" class="mrgin_btm">
        <a-button
          class="editable-add-btn"
          type="primary"
          @click="showModal"
          v-if="!loading"
          >Nouvelle
        </a-button>
      </a-col>
      <a-col :xs="24" :sm="4" class="mrgin_btm">
        <search-component @change="search($event)" />
      </a-col>
    </a-row>

    <a-row type="flex" justify="center">
      <a-col :span="24">
        <ListDemandesOuvertures
          :data-source="demandes"
          :processing="processing"
          :is-admin="false"
          @visite="goToVisite($event)"
          @edit="onEdit($event)"
          @delete="onDelete($event)"
        />
      </a-col>
    </a-row>
    <a-modal
      v-model="successMessageModalVisible"
      title="Votre dossier a été soumis"
      centered
      @ok="() => (successMessageModalVisible = false)"
    >
      <span v-html="successMessageBody"></span>
    </a-modal>
    <necessary-paiement-message @close="closePaiementErrorModal" :visible="paiementErrorMessageModalVisible" />
  </div>
</template>
<script>
import ListDemandesOuvertures from "@/components/common/ListDemandesOuvertures";
import DemandesOuvertureEditor from "@/components/common/DemandesOuverturesEditor";
import NecessaryPaiementMessage from "@/components/common/NecessaryPaiementMessage";

import SearchComponent from "@/components/common/Search";
import {mapActions, mapGetters, mapState} from "vuex";
import _ from "lodash";

export default {
  Name: "DemandesOuverturesIndex",
  components: {
    ListDemandesOuvertures,
    DemandesOuvertureEditor,
    SearchComponent,
    NecessaryPaiementMessage,
  },
  created() {
    this.getUser();
    this.fetchData();
    this.getAdditionalData();
  },
  data() {
    this.search = _.debounce(this.search, 750);
    return {
      loading: false,
      visible: false,
      processing: true,
      selectedDemande: null,
      newDemandeModalKey: 0,
      successMessageModalVisible: false,
      paiementErrorMessageModalVisible: false,
      successMessageBody: "",
      medecinList: []
    };
  },

  computed: {
    ...mapState({
      demandes: (state) => state.demandes.demandes,
      user: (state) => state.adherent.adherent,
      users: (state) => state.messageries.paid_users_emails,
    }),
    ...mapGetters({
      demandeOuvertureSuccessMessage: "getDemandeOuvertureSuccessMessage",
      demandeTransfertSuccessMessage: "getDemandeTransfertSuccessMessage",
    }),
  },
  methods: {
    closePaiementErrorModal() {
      this.paiementErrorMessageModalVisible = false;
    },
    showPaiementErrorModal() {
      this.paiementErrorMessageModalVisible = true;
    },
    goToVisite(id) {
      this.$router.push({ name: "users_visites", params: { id } });
    },
    getAdditionalData() {
      this.$store.cache.dispatch("fetchPaiedUsersEmails");
      this.$store.cache.dispatch("fetchTypeDemandes");
      this.$store.cache.dispatch("fetchMessageCommunication");
    },
    closeModal() {
      this.selectedDemande = null;
      this.visible = false;
    },
    showModal() {
         if (!this.isUserAccepter(this.user.etat)) {
        this.$_throwAnError("L'administration n'a pas encore accepté votre demande, vous ne pouvez pas ajouter un paiement.");
        return;
      }
      if (!this.isUserPaiementSettled(this.user)) {
        this.displayPaiementMandatoryError();
        return;
      }
      this.genererateNewDemandeModalKey();
      this.visible = true;
    },
    displayPaiementMandatoryError() {
      this.showPaiementErrorModal();
    },
    genererateNewDemandeModalKey() {
      this.newDemandeModalKey = this.getRandomInt();
    },
    onDelete(id) {
      this.delete(id).then(() => this.fetchData());
    },
    onEdit(id) {
      this.edit(id).then((response) => {
        this.selectedDemande = response.data;
        this.showModal();
      });
    },
    handleCreate() {
      const form = this.$refs.DemandeForm.form;
      let that = this;
      form.validateFields((err, values) => {
        if (!err) {
          this.startLoading();
          values.medecin_list = this.medecinList
          values["dt_demande"] = this.formatDate(
              values["dt_demande"],
              "YYYY/MM/DD"
          );
          if (this.selectedDemande) {
            this.update({ id: this.selectedDemande.id, ...values })
              .then((response) => {
                if (response.status === that.HTTP_OK) {
                  that.closeModal();
                  form.resetFields();
                  that.fetchData();
                }
              })
              .finally(() => that.stopLoading());
            return;
          }
          this.add({ ...values })
            .then((response) => {
              if (response.status === that.HTTP_OK) {
                that.closeModal();
                form.resetFields();
                that.displayDemandeSuccessMessage(response.data.type.code);
                that.fetchData();
              }
            })
            .finally(() => that.stopLoading());
        }
      });
    },
    displayDemandeSuccessMessage(typeDemande) {
      let successMessages = {
        OC: this.demandeOuvertureSuccessMessage,
        CG: this.demandeOuvertureSuccessMessage,
        TRS: this.demandeTransfertSuccessMessage,
      };
      this.successMessageBody = this.get(successMessages, typeDemande);
      this.successMessageModalVisible = true;
    },
    fetchData(q = "") {
      this.startProcessing();
      this.getDemandes({ q: q })
        .then(() => this.stopProcessing())
        .catch(() => this.stopProcessing());
    },
    startProcessing() {
      this.processing = true;
    },
    search(value) {
      this.fetchData(value);
    },
    stopProcessing() {
      this.processing = false;
    },
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    ...mapActions({
      getUser: "fetchAdherent",
      getDemandes: "fetchDemandes",
      add: "storeDemandes",
      edit: "fetchDemandeById",
      delete: "destroyDemande",
      update: "updateDemande",
    }),
  },
};
</script>

<style scoped>
.mrgin_btm {
  margin-bottom: 3%;
}
</style>
